/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

body {
  margin: 0;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42857143;
  background-color: white;
}

ion-toolbar {
  --color: #ffffff;
  --background: linear-gradient(to right, #A3BF42 0%, #719C3E);
  ion-menu-button {
    color: var(--ion-color-primary-contrast);
  }
  ion-title {
    font-weight: 500;
    text-transform: uppercase;
  }
}

ion-content {
  .sw-hdr {
    h2 {
      font-size: 35px;
      font-weight: 600;
      text-transform: capitalize;
      font-family: 'Roboto', sans-serif;
      color: var(--ion-color-md-green);
      margin-top: 0;
    }
    p {
      font-weight: 500;
      color: var(--ion-color-green);
    }
  }
  ion-item {
    --ripple-color: transparent;
    --padding-end: 0;
    --inner-padding-end: 0;
  }
  ion-label {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
  }
  .sw-chk {
    ion-label {
      font-size: 17px;
      font-weight: 400 !important;
      letter-spacing: 0.025em;
      margin: 10px 0 10px;
    }
  }
  ion-checkbox {
    margin-right: 10px !important;
    --border-radius: 0;
    --border-width: 0.190em;
    --border-color: var(--ion-color-md-green);
    --border-color-checked: var(--ion-color-md-green);
    --background-checked: var(--ion-color-md-green);
    --checkmark-color: #ffffff;
  }
  .sw-btn {
    --color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    height: 50px;
    --background:  #007bff;
   border: 10px solid #007bff;
    border-radius: 20px;
  }
  ion-backdrop {
    opacity: 0.85 !important;
  }
  .bold { font-weight: 600; }
  .bolder { font-weight: 500; }
  .mute { color: var(--ion-color-sw-green) }
  .deep { color: var(--ion-color-md-green) }
  .dark { color: var(--ion-color-green) }
}

#menu-avatar {
  .icon-badge {
    top: 0px;
    right: 0px;
    background: #FF5D5D;
    color: white;
    font-size: 12px;
    position: absolute;
    padding: 5px 8px;
    border-radius: 15px;
  }
  .user-avatar {
    padding: .5em;
  }
  h3, .label {
    color: white;
    font-weight: bold;
  }
  .item {
    background: transparent;
  }
  ion-content {
    background: linear-gradient(48deg, rgba(157, 72, 173, 0.8) 0%, rgba(157, 72, 173, 0.86) 28%, rgba(0, 168, 168, 0.98) 93%, rgba(0, 168, 168, 1) 100%);
  }
  .menu-inner {
    background: rgba(255, 255, 255, .3);
  }
}

.hide-card {
  -webkit-box-shadow: none !important;
}

.custom-avatar {
  height: 30vw;
  width: 30vw;
  border: 4px solid var(--ion-color-md-green);
  border-radius: 50%;
  padding: 3px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.otp-input {
  width: 100% !important;
  //height: 50px !important;
  border-radius: 0 !important;
  border-width: 1px !important;
  border-color: var(--ion-color-md-green) !important;
  color: var(--ion-color-md-green);
  /*font-weight: 600 !important;
  font-size: 40px !important;*/
  &::placeholder {
    color: var(--ion-color-md-green);
    font-weight: normal !important;
  }
  &:not(:last-child) {
    margin-right: 5px !important;
  }
}

textarea, select, input, button {
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
  justify-content: left;
  color: var(--ion-color-green);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
}

.action-sheet-button.sc-ion-action-sheet-ios
.action-sheet-icon.sc-ion-action-sheet-ios {
  margin-right: unset;
  -webkit-margin-end: 0.50em;
  margin-inline-end: 0.50em;
}

.action-sheet-button.sc-ion-action-sheet-ios {
  height: 60px;
}

.action-sheet-title.sc-ion-action-sheet-ios {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}

.action-sheet-button.sc-ion-action-sheet-ios
.action-sheet-icon.sc-ion-action-sheet-ios {
  margin-right: unset;
  -webkit-margin-end: 0.35em;
  margin-inline-end: 0.35em;
}

ion-backdrop {
  --backdrop-opacity: 0.875;
}

.sf-wrap {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 3% auto;
  .sf-text {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    color: #fff;
    padding: 10px;
    text-transform:uppercase;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sf-file {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

img.avatar.gray {
  filter: grayscale(1);
}


